<template>
    <BaseMenu :items="items" menu-classes="min-w-48">
        <BaseButton
            variant="text"
            icon
            :class="dark && '!bg-transparent !text-white hover:!opacity-80'"
        >
            <UserCircleIcon
                class="!h-8 !w-8 text-lg"
                :class="!dark && '!text-gray-500'"
            />
        </BaseButton>

        <template #items-prepend>
            <div class="p-2 text-sm text-slate-600">
                <p class="font-bold">{{ userEmail }}</p>
                <p>{{ userName }}</p>
            </div>
            <hr class="my-1" />
        </template>
    </BaseMenu>
</template>

<script setup lang="ts">
import {
    ArrowRightStartOnRectangleIcon,
    Cog8ToothIcon,
    GlobeAltIcon,
    UserCircleIcon,
} from '@heroicons/vue/24/outline'
import type { LocaleObject } from '@nuxtjs/i18n'
import type { MenuItem } from '../base/Menu/MenuItem.vue'

const { t, setLocale, locales, localeProperties: activeLocale } = useI18n()
const authStore = useAuthStore()

defineProps({
    dark: {
        type: Boolean,
        default: false,
    },
})

const userEmail = computed(() => {
    return authStore.user?.email
})
const userName = computed(() => {
    return `${authStore.user?.firstName} ${authStore.user?.lastName}`
})

function switchLocale() {
    const localeEntities = locales as ComputedRef<LocaleObject[]>
    const otherLocale = localeEntities.value.find(
        (i) => i.code !== activeLocale.value.code,
    )!
    setLocale(otherLocale.code)
}

const items = computed<MenuItem[]>(() => {
    return [
        {
            text: activeLocale.value?.name,
            icon: GlobeAltIcon,
            onclick: () => switchLocale(),
        },
        {
            text: t('account'),
            icon: Cog8ToothIcon,
            onclick: () => navigateTo('/settings/profile'),
        },
        {
            text: t('sign-out'),
            icon: ArrowRightStartOnRectangleIcon,
            danger: true,
            onclick: authStore.onLogout,
        },
    ]
})
</script>
